<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header flex gap-2 flex-col lg:flex-row lg:items-center lg:gap-4">
					<div class="input-group input-group-flush d-flex flex-row-reverse">
						<input
							v-model.trim="search"
							class="form-control list-search"
							type="search"
							placeholder="Search"
							/>
						<span class="input-group-text border-0">
							<i
								class="fe fe-search"
								@click.prevent="fetchIntrestedPartners(true)"
								></i>
						</span>
					</div>
          <div class="w-full flex flex-col sm:flex-row sm:items-center gap-y-2 gap-x-4 sm:justify-between lg:w-[400px]">
            <date-picker :range="true" class="shrink-0 sm:max-w-[220px]" v-model="date" valueType="format" placeholder="Filter by created date"></date-picker>
            <DownloadButton class="!shrink-0" :downloading="downloadingReport" label="Download Report" @download="downloadReport()" />
          </div>
				</div>
				<b-table
					striped
					hover
					selectable
					responsive
					show-empty
					:items="partners"
					:fields="fields"
					:current-page="currentPage"
					:busy="fetchingPartners"
					@row-clicked="showPartnerDetails"
					>
					<template #table-busy>
						<div
							class="d-flex flex-column justify-content-center align-items-center"
							>
							<div class="spinner-border" role="status"></div>
							<p class="text-center mt-2"><strong>Loading...</strong></p>
						</div>
					</template>
					<template #empty>
						<p class="text-center text-secondary">
							{{
								search
									? `No records found for search value: "${search}"`
									: 'No records available'
							}}
						</p>
					</template>
					<template #cell(serialNumber)="data">
						<p>
							{{ data.index + 1 }}
						</p>
					</template>
					<template #cell(name)="data">
						<div class="d-flex align-items-center">
							<user-avatar :user="data.item" size="sm"></user-avatar>
							<p class="ml-2">{{ data.item.fname }} {{ data.item.lname }}</p>
						</div>
					</template>
					<template #cell(company_name)="data">
						{{ data.item.company_name }}
					</template>
					<template #cell(email)="data">
						{{ data.item.email }}
					</template>
					<template #cell(phone)="data">
						{{ data.item.phone }}
					</template>
          <template #cell(created_at)="data">
						{{ moment(data.item.created_at).format('Do MMMM, YYYY') }}
					</template>
				</b-table>

				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div>
						<div class="col-auto">
							<b-pagination
								v-model="currentPage"
								:total-rows="totalRecords"
								:per-page="pageSize"
								></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import UserAvatar from '@/components/core/UserAvatar.vue'
import moment from 'moment'
import TableView from '@/mixins/tableview'
import Papa from 'papaparse'
import { axiosInstance as axios } from '@/plugins/axios.js'
import { downloadFile } from '@/composables/utils'
import DatePicker from 'vue2-datepicker'
import {addToQuery, removeQueryParam} from '@/composables/utils'
import router from '@/router'
import DownloadButton from '@/components/core/DownloadButton.vue'

export default {
  mixins: [TableView],
  name: 'IntrestedPartnersList',
  components: {
    UserAvatar, DownloadButton, DatePicker
  },
  data () {
    return {
      fields: [
        {
          key: 'serialNumber',
          label: 'S/N'
        },
        {
          key: 'name',
          label: 'Name'
        },
        {
          key: 'company_name',
          label: 'Company Name'
        },
        {
          key: 'email',
          label: 'Email'
        },
        {
          key: 'phone',
          label: 'Phone'
        },
        {
          key: 'created_at',
          label: 'Date Created'
        }
      ],
      pageSize: 20,
      currentPage: 1,
      totalRecords: 0,
      partners: [],
      fetchingPartners: true,
      search: '',
      debounce: null,
      date: [],
      downloadingReport: false,
      moment
    }
  },
  computed: {
    dateRange () {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },

    dateFilterValid () {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  methods: {
    fetchIntrestedPartners (reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingPartners = true

      const baseUrl = '/v1/partner-interests'

      const url = `${baseUrl}?sort[created_at]=desc&metadata=true&limit=${this.pageSize}&page=${this.currentPage}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}${this.search ? `&search=${this.search}` : ''}`
      const request = this.axios.get(url)
      request
        .then((res) => {
          this.partners = res.data.data
          this.totalRecords = res.data?.metadata?.total
          return this.partners
        })
        .finally(() => (this.fetchingPartners = false))
    },
    async downloadReport() {
      this.downloadingReport = true
      try {
        const res = await axios.get(`/v1/partner-interests?metadata=true&limit=${this.pageSize}&page=1&sort[created_at]=desc${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`)
        if(res.data?.data?.length){
          const total = res.data?.metadata?.total
          const resp = await axios.get(`/v1/partner-interests?metadata=true&limit=${total}&page=1&sort[created_at]=desc${this.search ? `&search=${this.search}` : ''}${this.date[0] && this.date[1] ? `&start_date=${this.date[0]}&end_date=${this.date[1]}` : ''}`)
          const x = resp.data?.data
          const newArr = []
          for (const el of x) {
            const y = {
              Name: `${el?.fname || '' } ${el?.lname || ''}`,
              Company_name: el?.company_name || '',
              Email: el?.email || 'N/A',
              Phone: el?.phone || 'N/A',
              Date_created: moment(el?.created_at).format('Do MMMM,YYYY') || 'N/A'
            }
            newArr.push(y)
          }

          const csv = Papa.unparse(newArr)
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
          const url = URL.createObjectURL(blob)
          downloadFile(url, 'interested-partners')
          this.downloadingReport = false
        } else {
          Swal.fire({ icon: 'error', title: 'Error', text: 'No data to download'})
          this.downloadingReport = false
        }
      } catch (err) {
        Swal.fire({ icon: 'error', title: 'Error', text: err?.response?.data?.message || 'No data to download'})
        this.downloadingReport = false
      }
    },

    showPartnerDetails (data) {
      this.$router.push({
        name: 'InterestedPartnerDetails',
        params: {
          partnerId: data.id
        }
      })
    },
    checkForFilters() {
      const q = this.$route.query
      if (q.search) this.search = q.search
      if(q.from && q.to) {
        this.date = [q.from, q.to]
      }
      this.fetchIntrestedPartners()
    }
  },
  watch: {
    search () {
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        if(this.search) {
          addToQuery(this.$route, router, {search: this.search})
        } else {
          removeQueryParam(this.$route, router, ['search'])
        }
        this.fetchIntrestedPartners(true)
      }, 600)
    },
    date () {
      if(this.date[0] && this.date[1]) {
        addToQuery(this.$route, router, {from: this.date[0], to: this.date[1]})
      } else {
        removeQueryParam(this.$route, router, ['from','to'])
      }
      this.fetchIntrestedPartners(true)
    },
    currentPage () {
      this.fetchIntrestedPartners()
    }
  },
  mounted() {
    this.checkForFilters()
  }
}
</script>

<style scoped lang="scss">
@use 'src/assets/scss/b-table';
</style>
